import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SpaceAdminHeader from './SpaceAdminHeader';
import { Modal } from '../layouts/Modal';
import EditSchedule from '../events/EditSchedule';
import { ApiStation, ApiSpaceDetails } from '../../api/ApiTypes';
import styled from 'styled-components/macro';
import { RedButton, SmallButton, SmallButtonWithOptions } from '../inputs/Button';
import { SpaceUtil } from '../../utils/SpaceUtil';
import { envAsBool } from '../../utils/envLoader';
import FrameUtil from '../../utils/FrameUtil';
import { postApi, putApi, getApi } from '../../api/api';
import FormErrorMessage from '../inputs/FormErrorMessage';
import Message from '../layouts/Message';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';
import { StreamIcon } from '../../images/icons';
import ScreenAudioAdjustments from './ScreenAudioAdjustments';
import InviteToSpace from './InviteToSpace';
import duplicateIcon from '../../images/i-duplicate.svg';
import welcomeIcon from '../../images/edit.svg';
import { getCurrentUserIsAdmin, getCurrentUser } from '../../store/selectors';
import { useSelector } from 'react-redux';
import LinkIcon from '../../images/space-link-icon.svg';
import InviteByLinkForm from './InviteByLinkForm';
import { OpenCheckbox } from '../inputs/Checkbox';
import ValidatedFormInput from '../inputs/ValidatedFormInput';
import InfoIcon from '../../images/atom-i-info-alt.svg';
import ReactTooltip from 'react-tooltip';
import { canEmbed, canAccessAdvancedEmbedOptions, canUseDistributedInvite } from '../../permissions/stations';
import { canAccessAdvancedSettings, canManageRacing } from '../../permissions/users';

type SpaceAdminProps = {
    spaceDetails: ApiSpaceDetails;
    station: ApiStation;
    setSpaceIsActive: (isActive: boolean) => void;
    regenerateInviteCode: (isHostCode: boolean, CodeonSuccess: () => void) => void;
    canDeleteSpace: boolean;
    meetInviteCode: string;
};

const SpaceAdminBody: React.FunctionComponent<SpaceAdminProps> = (props) => {
    const history = useHistory();
    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const currentUser = useSelector(getCurrentUser);

    const [isEditingSchedule, setIsEditingSchedule] = useState(false);
    const [showSpaceDeleteModal, setShowSpaceDeleteModal] = useState(false);
    const [error, setError] = useState('');
    const [showScreenAdjustmentModal, setShowScreenAdjustmentModal] = useState(false);
    const [isShowingAdvancedEmbemd, setIsShowingAdvancedEmbemd] = useState(false);
    const [embedAuthProvider, setEmbedAuthProvider] = useState('');
    const [embedUserToken, setEmbedUserToken] = useState('USER-TOKEN-PLACE-HOLDER');
    const [isEmbedUserManager, setIsEmbedUserManager] = useState(false);
    const [isEmbedSpectator, setIsEmbedSpectator] = useState(false);
    const [isEmbedDistributionInvite, setIsEmbedDistributionInvite] = useState(false);
    const [embedSkyboxUrl, setEmbedSkyboxUrl] = useState('');

    const { station, spaceDetails, setSpaceIsActive, regenerateInviteCode, canDeleteSpace, meetInviteCode } = props;

    const [distributionInviteCode, setDistributionInviteCode] = useState('');
    const { space } = spaceDetails;
    const [showRegenerateHostModal, setShowRegenerateHostModal] = React.useState(false);
    const [showRegenerateInviteModal, setShowRegenerateInviteModal] = React.useState(false);
    const [showDuplicateSpaceModal, setShowDuplicateSpaceModal] = React.useState(false);
    const [isWelcomeWorld, setIsWelcomeWorld] = React.useState(false);
    const [showWelcomeWorldModal, setShowWelcomeWorldModal] = React.useState(false);
    const [showSetNewSpaceDefaultModal, setShowSetNewSpaceDefaultModal] = React.useState(false);
    const [embedCode, setEmbedCode] = React.useState("");
    const [inviteSpectateCode, setInviteSpectateCode] = React.useState("");

    const deleteSpace = () => {
        postApi(`/space/${space.id}/delete`)
            .then(console.log)
            .then(() => history.push(`/station/${space.stationId}/admin`))
            .catch(setError);
    };

    const duplicateSpace = () => {
        postApi(`/station/${space.stationId}/${space.id}/duplicate`)
            .then(async (result) => {
                const drj = await result.json();
                history.push(`/space/${drj.id}/admin`);
                setShowDuplicateSpaceModal(false);
            })
            .catch(setError)
    }

    const setSpaceToWelcomeWorld = () => {
        putApi(`/space/${space.id}/makeWelcomeWorld`)
            .then(async (result) => {
                const r = await result.json();
                setIsWelcomeWorld(r.success);
                setShowWelcomeWorldModal(false);
                console.log("Make Welcome World Result: ", r);
            })
            .catch(setError)
    }

    const setAsNewSpaceDefault = () => {
        putApi(`/space/${space.id}/default`)
            .then(async (result) => {
                const r = await result.json();
                setShowSetNewSpaceDefaultModal(false);
                console.log("Set new space default Result: ", r);
            })
            .catch(setError)
    }

    const title = space?.title;

    const setInviteUserToSpectateLink = () => {
      getApi(`/meet/space/${space!.id!}?roleGrant=SPECTATOR`).then(async (result) => {
          const r = await result.json();
          setInviteSpectateCode(r.meetInviteCode);
      }).catch((e: Error) => {
          console.log('Could not get spectate code with error: ', e);
      })
    }

    const setEmbedToSpectate = (isSpec: boolean) => {
      if(isSpec && isEmbedUserManager) setIsEmbedUserManager(false);
      setIsEmbedSpectator(isSpec);
    }

    const setEmbedManager = (isMan: boolean) => {
      if(isMan && isEmbedSpectator) setIsEmbedSpectator(false);
      setIsEmbedUserManager(isMan);
    }

    const setEmbedToDistributionInvite = (isDis: boolean) => {
       if(isDis && isEmbedDistributionInvite) setIsEmbedDistributionInvite(false);
      setIsEmbedDistributionInvite(isDis);
    }

    const getSetDistributionInviteCode = async () => {
      console.log((station?.id?? 'NO'), { station });
      const dic = await getApi(`/meet/station/${station!.id!}/distribution/code`).then(async (res) => {;
        const result = await res.json();
        return result.code;
      }).catch(() => '');

      console.log({ dic });
      setDistributionInviteCode(dic);
    };

    const createEmbedCode = (mic: string, smic: string, isAdvanced?: boolean) => {
      const ic = (() => {
        if(isEmbedDistributionInvite) return distributionInviteCode;
        return (isEmbedSpectator)? smic: mic;
      })();
      //const ic = (isEmbedSpectator)? smic: mic;
      const framePerms = FrameUtil.allowPermissions([]).join(' *;') + ' *;';
      const baseUrl = document.location.protocol + '//' + document.location.host;
      const route = (isAdvanced && embedAuthProvider)? [`/embedNowhere`]: [`/spaceEntry/${ic}`];
      const query = ['ui=v2'];

      if(isAdvanced) {
         if(embedAuthProvider) route[1] = `/3rdPartyAuth/${embedAuthProvider}/${ic}`;
         if(embedUserToken && embedAuthProvider) query[1] = `&userToken=${embedUserToken}`;
         if(isEmbedUserManager) query[2] = `&inviteManagerCode=${station.inviteManagerCode}`;
         if(embedSkyboxUrl) query[3] = `&skyboxUrl=${encodeURIComponent(embedSkyboxUrl)}`;
      }

      const fullUrl = `${baseUrl}${route.join('')}?${query.join('')}`;

      const style = "width: 100%; height: 100%; border: 0px; padding: 0px; margin: 0px;";
      setEmbedCode(
        `<iframe src="${fullUrl}" allow="${framePerms}" style="${style}"></iframe>`
      );
    }

    useEffect(() => {
        setIsWelcomeWorld(space.isWelcomeWorld?? false);
        setInviteUserToSpectateLink();
        getSetDistributionInviteCode();
    }, [space]);

    useEffect(() => {
      createEmbedCode(meetInviteCode, inviteSpectateCode);
     }, [meetInviteCode, inviteSpectateCode])

    useEffect(() => {
      createEmbedCode(meetInviteCode, inviteSpectateCode, isShowingAdvancedEmbemd);
    }, [isShowingAdvancedEmbemd, isEmbedUserManager, isEmbedSpectator, embedAuthProvider, embedUserToken, embedSkyboxUrl, isEmbedDistributionInvite])

    return (
        <>
            <Modal handleClose={() => setShowDuplicateSpaceModal(false)} isOpen={showDuplicateSpaceModal}>
                <Message
                    title={`Are you sure you want to duplicate the space "${title}"?`}
                    body=""
                    onCancel={() => setShowDuplicateSpaceModal(false)}
                    onConfirm={duplicateSpace}
                    confirmButtonText={'Duplicate Space'}
                />
            </Modal>
            <Modal handleClose={() => setShowWelcomeWorldModal(false)} isOpen={showWelcomeWorldModal}>
                <Message
                    title={`Are you sure you want to make the space "${title}" the welcome world?`}
                    body=""
                    onCancel={() => setShowWelcomeWorldModal(false)}
                    onConfirm={setSpaceToWelcomeWorld}
                    confirmButtonText={'Set As Welcome World'}
                />
            </Modal>
            <Modal handleClose={() => setShowSetNewSpaceDefaultModal(false)} isOpen={showSetNewSpaceDefaultModal}>
                <Message
                    title={`Snapshot the current space (environment + space state) as new space default`}
                    body=""
                    onCancel={() => setShowSetNewSpaceDefaultModal(false)}
                    onConfirm={setAsNewSpaceDefault}
                    confirmButtonText={'Set New Space Defaults'}
                />
            </Modal>
            <Modal handleClose={() => setShowSpaceDeleteModal(false)} isOpen={showSpaceDeleteModal}>
                <Message
                    title={`Are you sure you want to delete the space ${title}?`}
                    body="This cannot be undone."
                    onCancel={() => setShowSpaceDeleteModal(false)}
                    onConfirm={deleteSpace}
                    confirmButtonText={'Delete Space'}
                />
            </Modal>
            <Modal handleClose={() => setIsEditingSchedule(false)} isOpen={isEditingSchedule}>
                <EditSchedule title={`Schedule for ${title}`} />
            </Modal>
            <Modal handleClose={() => setShowScreenAdjustmentModal(false)} isOpen={showScreenAdjustmentModal}>
                <ScreenAudioAdjustments space={space} />
            </Modal>

            <Modal handleClose={() => setShowRegenerateHostModal(false)} isOpen={showRegenerateHostModal}>
                <Message
                    title="Are you sure you want to generate a new link?"
                    body="All previous invite links will no longer work. ONLY people with the new link will be able to join"
                    onCancel={() => setShowRegenerateHostModal(false)}
                    onConfirm={() => regenerateInviteCode(true, () => setShowRegenerateHostModal(false))}
                    confirmButtonText="I Understand, Generate New Link"
                >
                    <br />
                    ::Note:: that this only applies to new attendees. This will not affect anyone who joined previously,
                    their access will remain unchanged.
                </Message>
            </Modal>
            <Modal handleClose={() => setShowRegenerateInviteModal(false)} isOpen={showRegenerateInviteModal}>
                <Message
                    title="Are you sure you want to generate a new link?"
                    body="All previous invite links will no longer work. ONLY people with the new link will be able to join"
                    onCancel={() => setShowRegenerateInviteModal(false)}
                    onConfirm={() => regenerateInviteCode(false, () => setShowRegenerateInviteModal(false))}
                    confirmButtonText="I Understand, Generate New Link"
                >
                    <br />
                    ::Note:: that this only applies to new attendees. This will not affect anyone who joined previously,
                    their access will remain unchanged.
                </Message>
            </Modal>
            <SpaceAdminHeader
                spaceDetails={spaceDetails}
                setSpaceIsActive={setSpaceIsActive}
                station={station}
                setIsEditingSchedule={setIsEditingSchedule}
            />

            <BottomContainer>
                <div>
                    {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StreamIcon style={{ marginRight: '30px' }} /> <h3> Screen Share Controls</h3>
                    </div>
                    <br />

                      * HIDING SCREENSHARE BUTTONS FOR NOW
                      * are not currently wired to the new screenshare system, and can currently
                      * be controlled from inside the 3d.  Will be added back at a future date,
                      * but removed for now.
                      *

                    <GreyishBodyText>Click below to share your screen to this space!</GreyishBodyText>
                      <br />
                    <ScreenshareButton streamName="nonagon" spaceId={space.id} size="small">
                        Screenshare To Nonagon
                    </ScreenshareButton>
                    <br />
                    <ScreenshareButton streamName="movie" spaceId={space.id} size="large">
                        Screenshare To Movie Screen
                    </ScreenshareButton>

                    <br />
                    <br /> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InviteToSpace
                            inviteCode={station.inviteCode!}
                            inviteHostCode={space.inviteHostCode!}
                            inviteSpectateCode={inviteSpectateCode}
                            space={space}
                        />
                    </div>
                    <RegenHostButton onClick={() => setShowRegenerateHostModal(true)}>
                        Generate New Host Link
                    </RegenHostButton>

                    {canEmbed(station) && (
                      <div style={{ marginTop: '20px' }}>
                        <InviteByLinkForm
                          icon={LinkIcon}
                          link={embedCode}
                          header="Embed Code"
                          tooltip="Use this code to embed your Nowhere space on the web"
                        >
                          {canAccessAdvancedEmbedOptions(station) && (
                            <CheckboxWrapper>
                              <OpenCheckbox
                                  name='isShowingAdvancedEmbemd'
                                  checked={isShowingAdvancedEmbemd}
                                  onChange={() => setIsShowingAdvancedEmbemd(!isShowingAdvancedEmbemd)}
                              />
                              <AdvancedEmbedLabel>Advanced Options</AdvancedEmbedLabel>
                            </CheckboxWrapper>
                          )}
                          {isShowingAdvancedEmbemd && (
                            <EmbedAdvancedOptionsWrapper>
                               <CheckboxWrapper>
                                <OpenCheckbox
                                    name='isEmbedUserManager'
                                    checked={isEmbedUserManager}
                                    onChange={() => setEmbedManager(!isEmbedUserManager)}
                                />
                                <label htmlFor="userToken">
                                  Make Station Manager
                                  <ReactTooltip effect="solid" />
                                <InactiveInfo data-tip="Will make the user that enters a station manager" src={InfoIcon} />
                              </label>
                              </CheckboxWrapper>
                              { !isEmbedDistributionInvite &&
                                <CheckboxWrapper>
                                  <OpenCheckbox
                                      name='isEmbedUserManager'
                                      checked={isEmbedSpectator}
                                      onChange={() => setEmbedToSpectate(!isEmbedSpectator) }
                                  />
                                  <label htmlFor="userToken">
                                    Spectator Mode
                                    <ReactTooltip effect="solid" />
                                  <InactiveInfo data-tip="When enabled any players who do not have the needed status will be put into a 3D preview mode and shown a redirect message" src={InfoIcon} />
                                </label>
                                </CheckboxWrapper>
                              }
                              { canUseDistributedInvite(station) && !isEmbedSpectator &&
                              <CheckboxWrapper>
                                <OpenCheckbox
                                    name='isEmbedDistributionInvite'
                                    checked={isEmbedDistributionInvite}
                                    onChange={() => setEmbedToDistributionInvite(!isEmbedDistributionInvite) }
                                />
                                <label htmlFor="userToken">
                                  Use Distribution Invite
                                  <ReactTooltip effect="solid" />
                                <InactiveInfo data-tip="When enabled players will be distributed as specified in your station distrubtion invite settings" src={InfoIcon} />
                              </label>
                              </CheckboxWrapper>
                              }

                              <label htmlFor="authProvider">
                                Auth Provider
                                <ReactTooltip effect="solid" />
                                <InactiveInfo data-tip="The name of your auth provider" src={InfoIcon} />
                              </label>
                              <ValidatedFormInput
                                  labelText=""
                                  className="w-input"
                                  maxLength={255}
                                  errors={ {} as any }
                                  id="EmbedAuthProvider"
                                  name="authProvider"
                                  onChange={(e: any) => { setEmbedAuthProvider(e.target.value) } }
                                  type="text"
                                  validation={() => { console.log('validation') } }
                                  value={embedAuthProvider}
                                  style={{ width: '300px' }}
                              />

                              <label htmlFor="userToken">
                                User Token
                                <ReactTooltip effect="solid" />
                                <InactiveInfo data-tip="Thrid party user token. Will need to be set dyamically replacing the place holder text here." src={InfoIcon} />
                              </label>
                              <ValidatedFormInput
                                  labelText=""
                                  className="w-input"
                                  maxLength={255}
                                  errors={ {} as any }
                                  id="EmbedUserToken"
                                  name="userToken"
                                  onChange={(e: any) => { console.log(e.target.value) } }
                                  type="text"
                                  validation={() => { console.log('validation') } }
                                  value={embedUserToken}
                                  style={{ width: '300px' }}
                              />

                              <label htmlFor="userToken">
                                Skybox URL
                                <ReactTooltip effect="solid" />
                                <InactiveInfo data-tip="The location of skybox to use in this space" src={InfoIcon} />
                              </label>
                              <ValidatedFormInput
                                  labelText=""
                                  className="w-input"
                                  maxLength={510}
                                  errors={ {} as any }
                                  id="EmbedSkyboxUrl"
                                  name="skyboxUrl"
                                  onChange={(e: any) => { setEmbedSkyboxUrl(e.target.value) } }
                                  type="text"
                                  validation={() => { console.log('validation') } }
                                  value={embedSkyboxUrl}
                                  style={{ width: '300px' }}
                              />
                            </EmbedAdvancedOptionsWrapper>
                          )}
                        </InviteByLinkForm>
                      </div>
                    )}
                </div>

                <div>
                    <div>
                         <SmallButtonWithOptions
                            onClick={() => setShowDuplicateSpaceModal(true)}
                            disabled={false}
                            icon={<img src={duplicateIcon} alt="Duplicate"/>}
                            activeColor="var(--indigo)"
                            baseColor="var(--off-white)"
                        >Duplicate Space</SmallButtonWithOptions><br />
                    {canDeleteSpace && (
                        <RedButton onClick={() => setShowSpaceDeleteModal(true)} disabled={false}>
                            Delete space
                        </RedButton>

                    )}
                    {isAdmin && !isWelcomeWorld && envAsBool('ALLOW_MAKE_WELCOME_WORLD') && (
                        <div><br/>
                            <SmallButtonWithOptions
                                onClick={() => setShowWelcomeWorldModal(true)}
                                disabled={isWelcomeWorld}
                                icon={<img src={welcomeIcon} alt="Set As Welcome World"/>}
                                activeColor="var(--indigo)"
                                baseColor="var(--tennis-green)"
                            >Make Welcome World</SmallButtonWithOptions>
                        </div>
                    )}
                    {isAdmin && (
                        <div><br/>
                            <SmallButtonWithOptions
                                onClick={() => setShowSetNewSpaceDefaultModal(true)}
                                icon={<img src={welcomeIcon} alt="Set New Space Defaults"/>}
                                activeColor="var(--indigo)"
                                baseColor="var(--tennis-green)"
                            >Set New Space Defaults</SmallButtonWithOptions>
                        </div>
                    )}
                     {canManageRacing(currentUser, space) && (
                        <div><br/>
                            <SmallButtonWithOptions
                                onClick={() => history.push(`/space/${space!.id!}/race`)}
                                icon={<img src={welcomeIcon} alt="Set Race Config"/>}
                                activeColor="var(--indigo)"
                                baseColor="var(--tennis-green)"
                            >Manage Racing</SmallButtonWithOptions>
                        </div>
                    )}
                    </div>
                    <br />
                    {error && <FormErrorMessage>Error: {error}</FormErrorMessage>}
                </div>
            </BottomContainer>
        </>
    );
};


const EmbedAdvancedOptionsWrapper = styled.div`
  margin-left: 25px;
`

const BottomContainer = styled.div`
    padding-top: 30px;
    padding-right: 45px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DupelicateSpaceButton = styled(SmallButton)`
    position: relative;
    background-color: transparent;
    border-color: white;
    border-width: medium;
    color: white;
`

const RegenHostButton = styled(SmallButton)`
    margin-left: 45px;
`;

const DeleteButton = styled(RedButton)`
    position: relative;
    top: -379px;
    right: 6px;
`;

const AdvancedEmbedLabel = styled.div`
    font-weight: bold;
    text-transform: uppercase;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;

    label {
        margin-bottom: 0;
    }
`;

const InactiveInfo = styled.img`
    margin-left: 10px;
    margin-top: -4px;
    fill: #ffffff;
`;

export default SpaceAdminBody;
